<script>
  /**
   * A switch that can be toggled on or off, with a label that can optionally be hidden.
   */
  export default {
    model: {
      prop: 'checked',
      event: 'change',
    },
    props: {
      /** Whether the switch is on. */
      checked: {
        type: Boolean,
        default: false,
      },
      /** Whether the switch is disabled. A disabled switch is grayed out and cannot be focused or toggled. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Whether the label is hidden, in which case only the switch is shown. Even when hidden, the label remains accessible to screen readers. */
      labelHidden: {
        type: Boolean,
        default: false,
      },
      /** The position of the label relative to the switch. */
      labelPosition: {
        type: String,
        default: 'right',
        validator: (value) => ['left', 'right'].includes(value),
      },
    },
    computed: {
      innerChecked: {
        get() {
          return this.checked
        },
        set(value) {
          /** Emitted when the switch is toggled on or off. */
          this.$emit('change', value)
        },
      },
      listeners() {
        const { change, ...listeners } = this.$listeners
        return listeners
      },
    },
  }
</script>

<template>
  <label :class="`flex ${disabled ? '' : 'cursor-pointer'}`">
    <input
      v-model="innerChecked"
      type="checkbox"
      :disabled="disabled"
      class="switch-input absolute left-0 top-0 opacity-0"
      v-on="listeners"
    />
    <span
      class="switch-button w-30px h-4 flex-shrink-0 rounded-full bg-dawn-dk4 transition duration-200"
    >
      <span
        class="switch-button-handle absolute left-0 top-0 -ml-1 w-5 h-5 -mt-2px rounded-full border border-black bg-dawn-lt1 transform transition duration-200"
      />
    </span>
    <span
      v-if="!labelHidden"
      :class="`switch-label ${
        labelPosition === 'left' ? 'order-first mr-3' : 'ml-3'
      } block flex-grow ${disabled ? 'text-gray-light' : ''} font-normal leading-normal`"
    >
      <span class="-m-1 block p-1 overflow-hidden ellipsis">
        <!-- @slot The switch’s label. Should contain text. -->
        <slot />
      </span>
    </span>
    <span v-else class="sr-only">
      <slot />
    </span>
  </label>
</template>

<style lang="postcss" scoped>
  .switch-button {
    .switch-input:not(:disabled):focus ~ & {
      @apply shadow-outline-primary-50;
    }

    .switch-input:not(:disabled):hover ~ & {
      .switch-button-handle {
        @apply shadow;
      }
    }

    .switch-input:not(:disabled):checked ~ & {
      @apply bg-gray-opacity68;
    }

    .switch-input:checked ~ & {
      .switch-button-handle {
        @apply translate-x-5;
      }
    }

    .switch-input:disabled ~ & {
      @apply bg-gray-lighter;

      .switch-button-handle {
        @apply border-black;
      }
    }
  }
</style>
